import React, { Fragment } from 'react';
import './style.sass';
import { Link } from 'react-router-dom';

export default function LowerFooter(props){
    const {
        contentSecion
    } = props

    return (
        <div className="lowerfooter">
            <div className="legales">
                {contentSecion?.length >= 1
                    ?
                        contentSecion?.map((action, index) => {
                            return(
                                <Fragment key={index}>
                                    <Link key={index} to={`${action.url}`} title={action.title} >{action.title}</Link>
                                    {contentSecion?.length >= 1 && index < (contentSecion?.length - 1)
                                        ? <span>|</span>
                                        : null
                                    }
                                </Fragment>
                            )
                        })
                    : null
                }
            </div>
        </div>
    )
}