import { BASE_PATH_PORTAL } from 'constants/general';
import React, { useState } from 'react';
import './style.sass';

export default function BoxDejanosAyudarte() {

    const [menuView, setMenuView] = useState(false);
    const toggleClass = () => {
        setMenuView(!menuView);
    };

    return (
        <div className="boxContacto">
            <p onClick={toggleClass} className={menuView ? 'active' : null} >DEJÁNOS AYUDARTE</p>
            <ul>
                <li><a href={`${BASE_PATH_PORTAL}/dudas-comentarios/`}>Dudas y Comentarios</a></li>
                <li><a href={`${BASE_PATH_PORTAL}/formas-pago/`}>Formas de Pago</a></li>
                <li><a href={`${BASE_PATH_PORTAL}/preguntas-frecuentes/`}>Preguntas Frecuentes</a></li>
                <li><a href={`${BASE_PATH_PORTAL}/preguntas-frecuentes-credito/`}>Preguntas Frecuentes  Crédito</a></li>
                <li><a href={`https://serviciosgfi.inbursa.com:4070/multiplataforma/mvc/others/autotalPublico/init?ca=PUBLICO&pa=P&pro=SEARS#no-back-button`} target="_blank" rel="noopener noreferrer">Autotal Inbursa</a></li>
            </ul>
        </div>
    )
}