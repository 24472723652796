import React, { useState, useEffect, useCallback } from 'react'
import { BlockContainerProducts, SliderContainerProducts, ScrollContainerProducts, RecomendationContainerProducts } from 'components/Emarsys/SlidersEmarsys/TypeContainerCards'

// logicas usadas ['HOME', 'RELATED','CART','CATEGORY','SEARCH','DEPARTMENT','ALSO_BOUGHT','PERSONAL','POPULAR']

export default function RecomendationEmarsys(props) {
    const {
        logica = 'PERSONAL',
        breadcrumb = null,
        idContent,
        idProd,
        searchTerm,
        cart,
        type = "slider",
        typeView = "scrollView",
        title,
        btnType = 'show',
        cant = 15,
        gadget,
        seccionPage
    } = props

    const [recomend, setRecomend] = useState()
    const [update, setUpdate] = useState(false)
    const [recomendProduct, setRecomendProduct] = useState()
    const [cantidad, setCantidad] = useState(cant)
    const [firstCantidad] = useState(cant)
    const [bread, setBread] = useState(breadcrumb)
    const [internalLogical, setInternalLogical] = useState(logica)
    const [idProduct, setIdProduct] = useState(idProd)
    const [howViewProduct] = useState(12)
    const [howViewClass] = useState('viewPer_' + howViewProduct)

    //- Hace el pdate de productos cuando estan en la pagina de producto si esta cambia por id o beadcrumb
    useEffect(() => {
        if (breadcrumb !== bread) {
            setBread(breadcrumb)
            setUpdate(true)
        }
        else if (idProd !== idProduct) {
            setIdProduct(idProd)
            setUpdate(true)
        }
    }, [bread, breadcrumb, idProd, idProduct])


    //- Ejecuta la primera ves la logica de emarsys
    // useEffect(() => {
    //     emarsisPersonal(cantidad, internalLogical, bread, idProduct)
    // }, [bread, cantidad, emarsisPersonal, idProduct, internalLogical])

    // //- Cuando se actualiza la cantidad, breadcrumb o id de producto se ejecuta el push
    // useEffect(() => {
    //     if (update) {
    //         if (cantidad || bread || idProduct) {
    //             emarsisPersonal(cantidad, internalLogical, bread, idProduct)
    //             setUpdate(false)
    //         }
    //     }
    // }, [bread, cantidad, emarsisPersonal, idProduct, internalLogical, update])

    //- Regressa los productos despues del recorrido si tiene menos de 3 regresa vacio
    useEffect(() => {
        if (recomend) {
            if (recomend.page) {
                if (recomend.page.products.length >= 4) {
                    setRecomendProduct(recomend.page.products)
                }
                else {
                    setRecomendProduct(null)
                    //emptyEmarsys()
                }
            }
        }
    }, [recomend])

    //- Funcion para guardar los datos en el state :S
    const returnDataEmarsys = (val) => {
        setRecomend(val)
    }
    //- Ejecuta las logicas de Emarsys
    // const emarsisPersonal = useCallback(()=> {})

    //- remueve contenedor cuando no tienen productos recomendados
    // const emptyEmarsys = () => {
    //     //console.log('remove: ',idContent)
    //     document.getElementById(idContent).remove()
    // }
    // boton para agregar mas productos
    const addRecomend = () => {
        setCantidad(cantidad + 15)
        setUpdate(true)
    }
    // boton para ver mas productos
    const showMoreRecomend = () => {
        setCantidad(cantidad + 15)
        setUpdate(true)
    }
    // boton para agregar mas productos
    const changeRecomend = (val) => {
        setBread('')
        setBread(val.bread)
        setInternalLogical(val.logical)
        setCantidad(firstCantidad)
        setUpdate(true)
    }

    //console.log("emnarsys disco yyyy:  seccionpage ",seccionPage, " origen origenBread: ",bread)

    return (
        <>
            {logica !== "GO"
                ?
                recomendProduct &&
                <>
                    {type === "slider"
                        ?
                        <>
                            {typeView === "scrollView"
                                ?
                                <ScrollContainerProducts
                                    title={title}
                                    typeView={typeView}
                                    recomendProduct={recomendProduct}
                                    addRecomend={addRecomend}
                                    seccionPage={seccionPage}
                                    origenBread={bread}
                                />
                                : null
                            }
                            {typeView === "blockView"
                                ?
                                <BlockContainerProducts
                                    title={title}
                                    typeView={typeView}
                                    recomendProduct={recomendProduct}
                                    addRecomend={addRecomend}
                                    seccionPage={seccionPage}
                                    origenBread={bread}
                                />
                                : null
                            }
                            {typeView === "sliderView"
                                ?
                                <SliderContainerProducts
                                    title={title}
                                    typeView={typeView}
                                    recomendProduct={recomendProduct}
                                    addRecomend={addRecomend}
                                    seccionPage={seccionPage}
                                    origenBread={bread}
                                />
                                : null
                            }
                        </>
                        :
                        <RecomendationContainerProducts
                            title={title}
                            typeView={typeView}
                            howViewClass={howViewClass}
                            recomendProduct={recomendProduct}
                            btnType={btnType}
                            addRecomend={addRecomend}
                            showMoreRecomend={showMoreRecomend}
                            changeRecomend={changeRecomend}
                            typeCard={'recomendation'}
                            gadget={gadget}
                            seccionPage={seccionPage}
                            origenBread={bread}
                        />
                    }
                </>
                : null
            }
        </>
    )
}